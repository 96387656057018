import { useState } from 'react';

export default function useToggle(initialState: boolean) {
  const [toggle, setToggle] = useState(initialState);

  return {
    set value(value: boolean) {
      setToggle(value);
    },
    get value() {
      return toggle;
    },
    set: setToggle,
    on() {
      setToggle(true);
    },
    off() {
      setToggle(false);
    },
    toggle() {
      setToggle((prev) => !prev);
    },
  };
}

