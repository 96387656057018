import {
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';

interface DarkModeContext {
  darkMode: boolean;
  toggleDarkMode(): void;
}

const DarkModeContext = createContext({} as DarkModeContext);

export const useDarkMode = () => useContext(DarkModeContext);

interface DarkModeProviderProps {
  children: ReactNode;
}

export default function DarkModeProvider({ children }: DarkModeProviderProps) {
  const mounted = useRef(false);

  const [darkMode, setDarkMode] = useState(false);

  const context: DarkModeContext = {
    darkMode,
    toggleDarkMode() {
      setDarkMode((prevState) => !prevState);
    },
  };

  useEffect(() => {
    mounted.current = true;

    const storageValue = localStorage.getItem('darkMode');

    setDarkMode(
      storageValue
        ? JSON.parse(storageValue)
        : window.matchMedia('(prefers-color-scheme: dark)').matches
    );

    return () => {
      mounted.current = false;
    };
  }, []);

  useEffect(() => {
    if (mounted.current) {
      localStorage.setItem('darkMode', JSON.stringify(darkMode));
    }
  }, [darkMode, mounted.current]);

  return (
    <DarkModeContext.Provider value={context}>
      {children}
    </DarkModeContext.Provider>
  );
}
