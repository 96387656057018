import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import ptBrTranslation from './pt-BR.json';
import esTranslation from './es.json';
import enTranslation from './en.json';

i18n.use(initReactI18next).init({
  resources: {
    ['pt-BR']: {
      translation: ptBrTranslation,
    },
    es: {
      translation: esTranslation,
    },
    en: {
      translation: enTranslation,
    },
  },
  fallbackLng: 'pt-BR',
  debug: true,
  interpolation: {
    escapeValue: false, // not needed for react as it escapes by default
  },
});

export default i18n;
