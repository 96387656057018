enum Status {
  NOT_CONNECTED,

  CONNECTED,
}

export default class PinterestAccount {
  public status: Status;

  public adAccountId?: string;
  public avatarURL?: string;
  public name?: string;
  public website?: string;
  public href?: string;
  public tag?: PixelTag;
  public catalog?: Catalog;

  constructor(status?: Status) {
    this.status = status ?? Status.NOT_CONNECTED;
  }

  setWebsite(website: string) {
    this.website = website;

    return this;
  }

  setAdAccountId(adAccountId: string) {
    this.adAccountId = adAccountId;

    return this;
  }

  setAvatarURL(avatarURL: string) {
    this.avatarURL = avatarURL;

    return this;
  }

  setName(name: string) {
    this.name = name;

    return this;
  }

  setHREF(href: string) {
    this.href = href;

    return this;
  }

  setTag(tag: PixelTag) {
    this.tag = tag;

    return this;
  }

  hasTag() {
    return this.tag != undefined;
  }

  setCatalog(catalog: Catalog) {
    this.catalog = catalog;

    return this;
  }

  hasCatalog() {
    return this.catalog != undefined;
  }

  isConnected(): boolean;
  isConnected(connected: boolean): this;
  isConnected(connected?: boolean) {
    if (connected != undefined) {
      if (connected) this.status = Status.CONNECTED;

      return this;
    } else {
      return this.status == Status.CONNECTED;
    }
  }

  isNotConnected(): boolean;
  isNotConnected(notConnected: boolean): this;
  isNotConnected(notConnected?: boolean) {
    if (notConnected != undefined) {
      if (notConnected) this.status = Status.NOT_CONNECTED;

      return this;
    } else {
      return this.status == Status.NOT_CONNECTED;
    }
  }
}
