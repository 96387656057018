import { useEffect, useState } from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import { ToastProvider } from '@nimbus-ds/components';
import { connect, iAmReady } from '@tiendanube/nexo/helpers';

import { nexo } from './services';
import { ConfigPage } from './pages';
import { BaseLayout, NexoSyncRoute } from './components';
import { DarkModeProvider } from './hooks';

function App() {
  const [isConnect, setIsConnect] = useState(false);

  useEffect(() => {
    connect(nexo).then(() => {
      setIsConnect(true);

      iAmReady(nexo);
    });
  }, []);

  if (!isConnect) return <div>connecting..</div>;

  return (
    <DarkModeProvider>
      <BrowserRouter>
        <ToastProvider>
          <BaseLayout>
            <NexoSyncRoute>
              <Switch>
                <Route
                  path="/"
                  exact
                >
                  <ConfigPage />
                </Route>
              </Switch>
            </NexoSyncRoute>
          </BaseLayout>
        </ToastProvider>
      </BrowserRouter>
    </DarkModeProvider>
  );
}

export default App;
