import Cookies from 'js-cookie'

export const getCookie = (key: string) => {
    let cookie: any = Cookies.get(key);
    
    if(!cookie){
        return null;
    }

    return cookie;
};

export const setCookie = (key: string, value: any, options?: any) => {
    options = { sameSite: 'None', secure: true, domain:'.empreender.com.br', ... options};
    return Cookies.set(key, value, options);
}

export const getParsedCookie = (key: string) => {
    let cookie: any = getCookie(key);
    return JSON.parse(cookie);
}

export const deleteCookie = (key: string) => {
    return Cookies.remove(key, {sameSite: 'None', secure: true, domain: '.empreender.com.br'});
}


