import { getSessionToken } from '@tiendanube/nexo/helpers';
import axios from 'axios';

import client from './nexo';

const http = axios.create({
  baseURL: process.env.REACT_APP_BACK_END_API_URL,
});

http.interceptors.request.use(
  async (request) => {
    const token = await getSessionToken(client);

    request.headers.Authorization = `Bearer ${token}`;

    return request;
  },
  (error) => Promise.reject(error)
);

const api = {
  async connectToPinterest(return_url: string) {
    const payload = {
      return_url,
    };

    const { data: response } = await http.post(
      '/api/oauth/redirect/pinterest',
      payload
    );

    return response as { redirect_to: string };
  },
  async me() {
    const { data: response } = await http.get('/nexo/me');

    return response.data as User;
  },
  async connectToNuvemShop(return_url: string) {
    const payload = {
      return_url,
    };

    const { data: response } = await http.post(
      '/api/oauth/redirect/nuvem-shop',
      payload
    );

    return response as { redirect_to: string };
  },
  async clearAllProviders() {
    return await http.delete('/api/stores/store/clear-provider');
  },
  async listCatalogs() {
    const { data: response } = await http.get('/api/catalogs');

    return response.data as { url: string }[];
  },
  async listQueuedJobs() {
    const { data: response } = await http.get('/api/stores/store/queued-jobs');

    return response.data;
  },
  async getUserWebsite() {
    const { data: response } = await http.get('/api/pinterest/users/websites');

    return response.data as { websites: Website[] };
  },
};

export default api;
