import { deleteCookie, getParsedCookie, setCookie } from "./cookieUtils"

export const flashWarnings = () => {
    let cookie_key = 'vercel_flash_warnings';
    let warnings = getParsedCookie(cookie_key);

    deleteCookie(cookie_key);
    
    return warnings;
}

export const flashMessages = () => {
    let cookie_key = 'vercel_flash_messages';
    let messages = getParsedCookie(cookie_key);

    deleteCookie(cookie_key);
    
    return messages;
}

export const flashErrors = () => {
    let cookie_key = 'vercel_flash_errors';
    let errors = getParsedCookie(cookie_key);

    deleteCookie(cookie_key);

    return errors;
}