import ReactDOM from 'react-dom';

import App from './App';

import './lang';
import './index.css';

import '@nimbus-ds/styles/dist/index.css';
import '@nimbus-ds/styles/dist/themes/dark.css';

ReactDOM.render(<App />, document.getElementById('root'));
