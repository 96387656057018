enum Status {
  // Error
  UNKNOWN,

  // Pending
  IMPORTING,
  UPLOADING,
  QUEUED_FOR_PROCESSING,
  PROCESSING,
  UNDER_REVIEW,

  // Success
  COMPLETED_EARLY,
  COMPLETED,

  // Fail
  FAILED,
}

export default class CatalogController implements Catalog {
  public status: Status;

  public href?: string;
  public src?: string;

  constructor(status?: Status, src?: string, href?: string) {
    this.status = status ?? Status.UNKNOWN;
    this.src = src;
    this.href = href;
  }

  setSRC(src: string) {
    this.src = src;

    return this;
  }

  setHREF(href: string) {
    this.href = href;

    return this;
  }

  // Success
  hasSucceeded(): boolean;
  hasSucceeded(succeeded: boolean): this;
  hasSucceeded(succeeded?: boolean) {
    if (succeeded != undefined) {
      if (succeeded) this.status = Status.COMPLETED;

      return this;
    } else {
      return (
        this.status == Status.COMPLETED_EARLY || this.status == Status.COMPLETED
      );
    }
  }

  // Fail
  hasFailed(): boolean;
  hasFailed(failed: boolean): this;
  hasFailed(failed?: boolean) {
    if (failed != undefined) {
      if (failed) this.status = Status.FAILED;

      return this;
    } else {
      return this.status == Status.FAILED;
    }
  }

  // Pending
  isImporting(): boolean;
  isImporting(importing: boolean): this;
  isImporting(importing?: boolean) {
    if (importing != undefined) {
      if (importing) this.status = Status.IMPORTING;

      return this;
    } else {
      return this.status == Status.IMPORTING;
    }
  }

  isUploading(): boolean;
  isUploading(uploading: boolean): this;
  isUploading(uploading?: boolean) {
    if (uploading != undefined) {
      if (uploading) this.status = Status.UPLOADING;

      return this;
    } else {
      return this.status == Status.UPLOADING;
    }
  }

  isBeingProcessed(): boolean;
  isBeingProcessed(beingProcessed: boolean): this;
  isBeingProcessed(beingProcessed?: boolean) {
    if (beingProcessed != undefined) {
      if (beingProcessed) this.status = Status.PROCESSING;

      return this;
    } else {
      return (
        this.status == Status.QUEUED_FOR_PROCESSING ||
        this.status == Status.PROCESSING
      );
    }
  }

  isBeingReviewed(): boolean;
  isBeingReviewed(underReview: boolean): this;
  isBeingReviewed(underReview?: boolean) {
    if (underReview != undefined) {
      if (underReview) this.status = Status.PROCESSING;

      return this;
    } else {
      return this.status == Status.UNDER_REVIEW;
    }
  }

  isPending(): boolean;
  isPending(pending: boolean): this;
  isPending(pending?: boolean) {
    if (pending != undefined) {
      if (pending) this.status = Status.PROCESSING;

      return this;
    } else {
      return (
        this.isImporting() ||
        this.isUploading() ||
        this.isBeingProcessed() ||
        this.isBeingReviewed()
      );
    }
  }

  // Error
  isUnknown(): boolean;
  isUnknown(unknown: boolean): this;
  isUnknown(unknown?: boolean) {
    if (unknown != undefined) {
      if (unknown) this.status = Status.UNKNOWN;

      return this;
    } else {
      return this.status == Status.UNKNOWN;
    }
  }

  static Status = Status;
}
