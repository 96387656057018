function isProvider(provider: ProviderName) {
  return (extension: Extension) => provider == extension.provider;
}

function createStore(store: Store) {
  const { extensions } = store;

  return {
    isConnectedTo(provider: ProviderName) {
      return extensions.some(isProvider(provider));
    },
    getExtension(provider: ProviderName) {
      const result = extensions.filter(isProvider(provider));

      return result.length ? result[0] : undefined;
    },
  };
}

export default createStore;
