import { Tag } from '@nimbus-ds/components';
import { CheckCircleIcon, ExclamationCircleIcon } from '@nimbus-ds/icons';

interface StatusTagProps {
  status: boolean | null;
  labels: {
    success: string;
    error: string;
  };
}

function StatusTag({ status, labels }: StatusTagProps) {
  return status == null ? (
    <Tag.Skeleton />
  ) : status ? (
    <Tag appearance="success">
      <CheckCircleIcon /> {labels.success}
    </Tag>
  ) : (
    <Tag appearance="danger">
      <ExclamationCircleIcon /> {labels.error}
    </Tag>
  );
}

export default StatusTag;
