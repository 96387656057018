import { useEffect } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { syncPathname } from '@tiendanube/nexo/helpers';
import {
  ACTION_NAVIGATE_SYNC,
  NavigateSyncResponse,
} from '@tiendanube/nexo/actions';

import client from '../../services/nexo';

interface NexoSyncRouteProps {
  children: JSX.Element;
}

function NexoSyncRoute({ children }: NexoSyncRouteProps) {
  const { pathname } = useLocation();
  const { push: goTo, replace: replaceTo } = useHistory();

  useEffect(() => {
    syncPathname(client, pathname);
  }, [pathname]);

  useEffect(() => {
    const handleNexoSync = (response: NavigateSyncResponse) => {
      const { path, replace } = response;

      replace ? goTo(path) : replaceTo(path);
    };

    return client.suscribe(ACTION_NAVIGATE_SYNC, handleNexoSync);
  }, [goTo, replaceTo]);

  return children;
}

export default NexoSyncRoute;
