import { useEffect, useState } from 'react';
import {
  Button,
  Box,
  Card,
  Text,
  Alert,
  Title,
  Modal,
  useToast,
  Link,
  Tag,
} from '@nimbus-ds/components';
import { HelpLink, Layout, Page } from '@nimbus-ds/patterns';
import {
  CheckCircleIcon,
  ExclamationCircleIcon,
  ExternalLinkIcon,
} from '@nimbus-ds/icons';
import { getStoreInfo, navigateHeaderRemove } from '@tiendanube/nexo/helpers';
import { useTranslation } from 'react-i18next';

import { useToggle } from '../hooks';
import { api, nexo } from '../services';
import { CatalogController, PinterestAccount } from '../controllers';
import { IconText, IconLink, StatusTag } from '../components';
import { flashErrors } from '../utils';
import createStore from '../lib/Store';

async function verifyCatalogs(account: PinterestAccount) {
  const catalogs = await api.listCatalogs();

  const catalog = new CatalogController();

  if (catalogs.length) {
    const [{ url }] = catalogs;

    catalog
      .hasSucceeded(true)
      .setSRC(url)
      .setHREF(
        `https://pinterest.com/business/catalogs?advertiserId=${account.adAccountId}`
      );
  } else {
    const {
      upload_x_m_l_catalog,
      import_batch_was_scheduled,
      import_batch_has_finished,
    } = await api.listQueuedJobs();

    if (import_batch_was_scheduled || import_batch_has_finished == false) {
      catalog.isImporting(true);
    } else if (upload_x_m_l_catalog) {
      catalog.isUploading(true);
    }
  }

  account.setCatalog(catalog);
}

async function verifyWebsite(account: PinterestAccount) {
  try {
    const { websites } = await api.getUserWebsite();

    const [site] = websites;

    if (site) account.setWebsite(site.website);
  } catch (e) {
    console.error(e);
  }
}

export default function ConfigPage() {
  const errorAlert = useToggle(false);
  const disconnectModal = useToggle(false);
  const greetingCard = useToggle(true);

  const { t, i18n } = useTranslation();

  const [pinterest, setPinterest] = useState<PinterestAccount | null>(null);
  const [authNuvemShop, setAuthNuvemShop] = useState<boolean | null>(false);

  const { addToast } = useToast();

  const verifyAccounts = async () => {
    errorAlert.off();

    const pinterestAccount = new PinterestAccount();

    setAuthNuvemShop(null);

    try {
      const user = await api.me();

      console.log({ user });

      const store = createStore(user.store);

      setAuthNuvemShop(store.isConnectedTo('nuvem_shop'));

      const extension = store.getExtension('pinterest');

      if (extension) {
        pinterestAccount
          .isConnected(true)
          .setAdAccountId(extension.ad_account_id ?? '')
          .setAvatarURL(extension.avatar_url ?? '/profile-picture.jpg')
          .setName(
            extension.business_name ??
              extension.identifier ??
              extension.provider_key ??
              ''
          )
          .setHREF(`https://br.pinterest.com/${extension.provider_key}`)
          .setTag({
            id: extension.conversion_tag_id ?? '',
            href: `https://ads.pinterest.com/advertiser/${extension.ad_account_id}/conversions/tag/`,
          });

        await verifyCatalogs(pinterestAccount);
        await verifyWebsite(pinterestAccount);
      }
    } catch (_) {
      errorAlert.on();
    }

    setPinterest(pinterestAccount);
  };

  const loadInfo = async () => {
    const storeInfo = await getStoreInfo(nexo);

    console.log({ storeInfo });

    i18n.changeLanguage(`${storeInfo.language}-${storeInfo.country}`);
  };

  const handleConnectToNuvemShop = async () => {
    try {
      const return_url = window.location.ancestorOrigins[0];

      const { redirect_to } = await api.connectToNuvemShop(return_url);

      window.open(redirect_to, '_parent', 'noopener,noreferrer');
    } catch (error) {
      console.error(error);
    }
  };

  const handleConnectToPinterest = async () => {
    try {
      const return_url = window.location.ancestorOrigins[0];

      const { redirect_to } = await api.connectToPinterest(return_url);

      window.open(redirect_to, '_parent', 'noopener,noreferrer');
    } catch (_) {
      errorAlert.on();
    }
  };

  const handleDisconnect = async () => {
    try {
      await api.clearAllProviders();

      const nuvemShopAppId = process.env.REACT_APP_NUVEM_SHOP_APP_ID;

      const redirect_to =
        window.location.ancestorOrigins[0] + `/admin/v2/apps/${nuvemShopAppId}`;

      window.open(redirect_to, '_parent', 'noopener,noreferrer');
    } catch (error) {
      console.error(error);
    } finally {
      disconnectModal.off();
    }
  };

  useEffect(() => {
    navigateHeaderRemove(nexo);

    loadInfo();

    verifyAccounts();

    let flash_errors = flashErrors();

    if (flash_errors) {
      addToast({
        id: performance.now().toString(),
        type: 'danger',
        text: flash_errors,
        duration: 16000,
      });
    }
  }, []);

  return (
    <Page maxWidth="800px">
      <Page.Header title="">
        <Box
          display="flex"
          padding="4"
          gap="2"
          alignItems="center"
        >
          <p
            style={{
              fontFamily:
                "CentraNube, -apple-system, BlinkMacSystemFont, Roboto, 'Helvetica Neue', arial, sans-serif",
              fontSize: '2rem',
              fontWeight: 'bold',
            }}
          >
            Pinterest by
          </p>
          <Link
            as="a"
            target="_blank"
            href="https://pxl.to/app-pinterest"
          >
            <img
              height={24}
              src="/empreender-logo.svg"
              alt="Boss Logo"
            />
          </Link>
        </Box>
        <Alert
          show={greetingCard.value}
          appearance="neutral"
          title={t('alerts.greeting.title')}
          onRemove={greetingCard.off}
        >
          <Text>{t('alerts.greeting.text')}</Text>
          <Button
            as="a"
            appearance="neutral"
            target="_blank"
            href="https://pxl.to/app-pinterest"
          >
            {t('alerts.greeting.button')}
            <ExternalLinkIcon />
          </Button>
        </Alert>
        <Alert
          show={errorAlert.value}
          appearance="danger"
          title={t('alerts.pinterest-error.title')}
          onRemove={errorAlert.off}
        >
          <Text>{t('alerts.pinterest-error.text')}</Text>
          <Button
            appearance="danger"
            type="button"
            onClick={verifyAccounts}
          >
            {t('alerts.pinterest-error.button')}
          </Button>
        </Alert>
      </Page.Header>
      <Page.Body>
        <Modal
          open={disconnectModal.value}
          onDismiss={disconnectModal.off}
        >
          <Modal.Header title={t('modal.header')} />
          <Modal.Body>
            <Text>{t('modal.text')}</Text>
          </Modal.Body>
          <Modal.Footer>
            <Button
              appearance="primary"
              onClick={handleDisconnect}
            >
              {t('modal.button')}
            </Button>
          </Modal.Footer>
        </Modal>
        <Layout columns="1">
          <Layout.Section>
            <Card>
              <Card.Header>
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Title as="h3">{t('cards.nuvemshop.title')}</Title>
                  <StatusTag
                    status={authNuvemShop}
                    labels={{
                      success: t('cards.nuvemshop.tags.success'),
                      error: t('cards.nuvemshop.tags.error'),
                    }}
                  />
                </Box>
              </Card.Header>
              <Card.Body>
                <Box
                  display="flex"
                  flexDirection="column"
                  gap="2"
                >
                  {authNuvemShop ? (
                    <Text>{t('cards.nuvemshop.status.success')}</Text>
                  ) : (
                    <Text>{t('cards.nuvemshop.status.error')}</Text>
                  )}
                  <Text>{t('cards.nuvemshop.text')}</Text>
                </Box>
              </Card.Body>
              <Card.Footer>
                {authNuvemShop == null ? (
                  <Button.Skeleton />
                ) : !authNuvemShop ? (
                  <Button
                    disabled={errorAlert.value}
                    appearance="primary"
                    type="button"
                    onClick={handleConnectToNuvemShop}
                  >
                    {t('cards.nuvemshop.button')}
                    <ExclamationCircleIcon />
                  </Button>
                ) : null}
              </Card.Footer>
            </Card>
          </Layout.Section>
          <Layout.Section>
            <Card>
              <Card.Header>
                <Title as="h3">{t('cards.disclaimer.title')}</Title>
              </Card.Header>
              <Card.Body>
                <Text>
                  {t('cards.disclaimer.texts.0')}
                  <Link
                    as="a"
                    appearance="primary"
                    target="_blank"
                    href="https://business.pinterest.com/en/pinterest-advertising-services-agreement"
                  >
                    {t('cards.disclaimer.links.eua-ca')}
                  </Link>
                  {t('cards.disclaimer.texts.1')}
                  <Link
                    as="a"
                    appearance="primary"
                    target="_blank"
                    href="https://business.pinterest.com/pt-br/pinterest-advertising-services-agreement"
                  >
                    {t('cards.disclaimer.links.other-regions')}
                  </Link>
                  {t('cards.disclaimer.texts.2')}
                  <Link
                    as="a"
                    appearance="primary"
                    target="_blank"
                    href="https://policy.pinterest.com/privacy-policy"
                  >
                    {t('cards.disclaimer.links.privacy')}
                  </Link>
                  {t('cards.disclaimer.texts.3')}
                </Text>
              </Card.Body>
            </Card>
          </Layout.Section>
          <Layout.Section>
            <Card>
              <Card.Header>
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Title as="h3">{t('cards.pinterest-account.title')}</Title>
                  <StatusTag
                    status={pinterest ? pinterest.isConnected() : null}
                    labels={{
                      success: t('cards.pinterest-account.tag.success'),
                      error: t('cards.pinterest-account.tag.error'),
                    }}
                  />
                </Box>
              </Card.Header>
              <Card.Body>
                <Text>{t('cards.pinterest-account.text')}</Text>
              </Card.Body>
              <Card.Footer>
                {pinterest == null ? (
                  <Button.Skeleton />
                ) : !pinterest.isConnected() ? (
                  <Button
                    // disabled={errorAlert.value || !pinterest.website}
                    appearance="primary"
                    type="button"
                    onClick={handleConnectToPinterest}
                  >
                    {t('cards.pinterest-account.button')}
                  </Button>
                ) : (
                  <Box
                    display="flex"
                    gap="2"
                    alignItems="center"
                  >
                    <img
                      src={pinterest.avatarURL}
                      alt="Avatar"
                      width="40"
                      height="40"
                      style={{ borderRadius: '9999px' }}
                    />
                    <Box
                      display="flex"
                      gap="1"
                      flexDirection="column"
                    >
                      <Text>{pinterest.name}</Text>
                      <Link
                        as="a"
                        appearance="primary"
                        target="_blank"
                        href={pinterest.href}
                      >
                        {pinterest.href}
                      </Link>
                    </Box>
                  </Box>
                )}
              </Card.Footer>
            </Card>
          </Layout.Section>
          <Layout.Section>
            <Card>
              <Card.Header title={t('cards.sites.title')} />
              <Card.Body>
                <Box
                  display="flex"
                  flexDirection="column"
                  gap="2"
                >
                  <Text>
                    {t('cards.sites.texts.0')}
                    <Link
                      as="a"
                      appearance="primary"
                      target="_blank"
                      href="https://help.pinterest.com/business/article/install-the-pinterest-tag"
                    >
                      {t('cards.sites.links.rules')}
                    </Link>
                    {t('cards.sites.texts.1')}
                    <Link
                      as="a"
                      appearance="primary"
                      target="_blank"
                      href="https://policy.pinterest.com/ad-data-terms"
                    >
                      {t('cards.sites.links.terms')}
                    </Link>
                    {t('cards.sites.texts.2')}
                  </Text>
                  {pinterest == null ? (
                    <>
                      <Text.Skeleton width="25%" />
                      <Text.Skeleton width="10%" />
                    </>
                  ) : pinterest.website ? (
                    <>
                      <IconLink
                        href={pinterest.website}
                        appearance="primary"
                      >
                        {pinterest.website}
                      </IconLink>
                      <IconText
                        color="success-textLow"
                        source={<CheckCircleIcon />}
                      >
                        {t('cards.sites.verified')}
                      </IconText>
                    </>
                  ) : (
                    <>
                      <IconLink
                        href="https://br.pinterest.com/settings/claim/"
                        appearance="primary"
                      >
                        {t('cards.sites.claim-site')}
                      </IconLink>
                      <IconText
                        color="danger-textLow"
                        source={<ExclamationCircleIcon />}
                      >
                        {t('cards.sites.not-verified')}
                      </IconText>
                    </>
                  )}
                </Box>
              </Card.Body>
            </Card>
          </Layout.Section>
          <Layout.Section>
            <Card>
              <Card.Header>
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Title as="h3">{t('cards.tag.title')}</Title>
                  <StatusTag
                    status={pinterest ? pinterest.hasTag() : null}
                    labels={{
                      success: t('cards.tag.tags.success'),
                      error: t('cards.tag.tags.error'),
                    }}
                  />
                </Box>
              </Card.Header>
              <Card.Body>
                <Box
                  display="flex"
                  flexDirection="column"
                  gap="2"
                >
                  {!pinterest?.hasTag() ? (
                    <Text>{t('cards.tag.texts.error')}</Text>
                  ) : (
                    <Text>{t('cards.tag.texts.success')}</Text>
                  )}
                  {pinterest == null ? (
                    <>
                      <Text.Skeleton width="20%" />
                      <Text.Skeleton width="25%" />
                    </>
                  ) : pinterest.tag ? (
                    <>
                      <Text>
                        {t('cards.tag.your-tag')}: {pinterest.tag.id}
                      </Text>
                      <IconLink
                        appearance="primary"
                        href={pinterest.tag.href}
                      >
                        {t('cards.tag.link')}
                      </IconLink>
                    </>
                  ) : null}
                </Box>
              </Card.Body>
            </Card>
          </Layout.Section>
          <Layout.Section>
            <Card>
              <Card.Header>
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Title as="h3">{t('cards.catalog.title')}</Title>
                  {pinterest == null ? (
                    <Tag.Skeleton />
                  ) : pinterest.catalog?.isImporting() ? (
                    <Tag appearance="warning">
                      {t(`cards.catalog.tag.importing`)}
                    </Tag>
                  ) : pinterest.catalog?.isUploading() ? (
                    <Tag appearance="warning">
                      {t(`cards.catalog.tag.uploading`)}
                    </Tag>
                  ) : pinterest.catalog?.isPending() ? (
                    <Tag appearance="warning">
                      {t(`cards.catalog.tag.under-review`)}
                    </Tag>
                  ) : pinterest.catalog?.hasSucceeded() ? (
                    <Tag appearance="success">
                      <CheckCircleIcon /> {t(`cards.catalog.tag.success`)}
                    </Tag>
                  ) : (
                    <Tag appearance="danger">
                      <ExclamationCircleIcon /> {t(`cards.catalog.tag.error`)}
                    </Tag>
                  )}
                </Box>
              </Card.Header>
              <Card.Body>
                <Box
                  display="flex"
                  flexDirection="column"
                  gap="2"
                >
                  {pinterest == null ? (
                    <Text.Skeleton width="100%" />
                  ) : pinterest.catalog?.isImporting() ? (
                    <Text>{t('cards.catalog.texts.importing')}</Text>
                  ) : pinterest.catalog?.isUploading() ? (
                    <Text>{t('cards.catalog.texts.uploading')}</Text>
                  ) : pinterest.catalog?.isPending() ? (
                    <Text>{t('cards.catalog.texts.under-review')}</Text>
                  ) : pinterest.catalog?.hasSucceeded() ? (
                    <Text>{t('cards.catalog.texts.success')}</Text>
                  ) : (
                    <Text>{t('cards.catalog.texts.error')}</Text>
                  )}
                  {pinterest == null ? (
                    <Text.Skeleton width="25%" />
                  ) : pinterest.catalog?.hasSucceeded() ? (
                    <IconLink
                      appearance="primary"
                      href={pinterest.catalog.href ?? ''}
                    >
                      {t('cards.catalog.link')}
                    </IconLink>
                  ) : null}
                </Box>
              </Card.Body>
            </Card>
          </Layout.Section>
          <Layout.Section>
            <Card>
              <Card.Header title={t('cards.account.title')} />
              <Card.Body>
                <Text>{t('cards.account.text')}</Text>
              </Card.Body>
              <Card.Footer>
                <Button
                  type="button"
                  appearance="danger"
                  onClick={disconnectModal.on}
                >
                  {t('cards.account.button')}
                </Button>
              </Card.Footer>
            </Card>
          </Layout.Section>
          <Layout.Section>
            <HelpLink>
              <IconLink
                appearance="primary"
                href="https://pxl.to/app-pinterest"
              >
                {t('help-link')}
              </IconLink>
            </HelpLink>
          </Layout.Section>
        </Layout>
      </Page.Body>
    </Page>
  );
}
