import { BoxColorProperties } from '@nimbus-ds/components';
import { Box, Icon, Text } from '@nimbus-ds/components';
import { ReactNode } from 'react';

interface IconTextProps {
  children: ReactNode;
  source: ReactNode;
  color?: BoxColorProperties;
}

function IconText({ color, source, children }: IconTextProps) {
  return (
    <Box
      display="flex"
      alignItems="center"
      gap="1"
      color={color}
    >
      <Icon
        color="currentColor"
        source={source}
      />
      <Text color="currentColor">{children}</Text>
    </Box>
  );
}

export default IconText;
