import { LinkProperties } from '@nimbus-ds/components';
import { Link, Icon } from '@nimbus-ds/components';
import { ReactNode } from 'react';
import { ExternalLinkIcon } from '@nimbus-ds/icons';

interface IconLinkProps {
  href: string;
  children: ReactNode;
  appearance: LinkProperties['appearance'];
}

function IconLink({ appearance, href, children }: IconLinkProps) {
  return (
    <Link
      as="a"
      href={href}
      appearance={appearance}
      target="_blank"
    >
      {children}
      <Icon
        color="currentColor"
        source={<ExternalLinkIcon />}
      />
    </Link>
  );
}

export default IconLink;
